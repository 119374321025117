import React from 'react'

const Footer = props => (
  <footer>
    <div className="container">
      <div className="col-md-12">
        <div className="col-md-7 col-xs-7">
          <img
            className="logo"
            src={props.locale === 'es' ? '/img/logoen.png' : '/img/logoen.png'}
            alt="Logo Venezuela"
          />
        </div>
        <div className="col-md-5 col-xs-5 text-center">
          <a href="https://www.linktic.com/" target="_blank">
            <img
              className="logo-linktic"
              src="/img/linktic.png"
              alt="Logo Linktic"
            />
          </a>
          <p className="text-linktic">
            Powered by{' '}
            <a href="https://www.linktic.com/" target="_blank">
              LINKTIC
            </a>
          </p>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
