import React from 'react'
import { Link } from 'gatsby'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'

const Header = props => (
  <div>
    <img className="img-date" src="/img/fecha.png" alt="Fecha del evento" />
    {props.locale === 'es' ? (
      <Link className="button-i8n" to="/">
        English
      </Link>
    ) : (
      <Link className="button-i8n" to="/es">
        Español
      </Link>
    )}

    <section className="banner">
      <div className="container">
        <div className="col-md-12">
          <div className="col-md-7">
            <img
              src={
                props.locale === 'es' ? '/img/logoen.png' : '/img/logoen.png'
              }
              alt="Logo Venezuela"
            />
            <br />
            <div className="col-md-12 div-social hidden-xs hidden-sm hidden-md">
              <div className="col-md-2 text-center">
                <p>
                  <a href="https://twitter.com/VenezuelaAid">
                    <i className="fab fa-twitter fa-3x" />
                  </a>
                </p>
              </div>
              <div className="col-md-2 text-center">
                <p>
                  <a href="https://www.facebook.com/VenezuelaAidLive/">
                    <i className="fab fa-facebook-f fa-3x" />
                  </a>
                </p>
              </div>
              <div className="col-md-2 text-center">
                <p>
                  <a href="https://www.instagram.com/venezuelaaidlive/">
                    <i className="fab fa-instagram fa-3x" />
                  </a>
                </p>
              </div>
              <div className="col-md-2 text-center">
                <p>
                  <a href="https://www.youtube.com/channel/UCWLdPPOhZchUcTUwIVelbGA">
                    <i className="fab fa-youtube fa-3x" />
                  </a>
                </p>
              </div>
              <div className="col-md-4" />
            </div>
          </div>
          <div className="col-md-5" />
        </div>
        <div className="col-md-12 div-social hidden-lg hidden-md">
          <div className="col-xs-2" />
          <div className="col-xs-2 text-center">
            <p>
              <a href="https://twitter.com/VenezuelaAid">
                <i className="fab fa-twitter fa-2x" />
              </a>
            </p>
          </div>
          <div className="col-xs-2 text-center">
            <p>
              <a href="https://www.facebook.com/VenezuelaAidLive/">
                <i className="fab fa-facebook-f fa-2x" />
              </a>
            </p>
          </div>
          <div className="col-xs-2 text-center">
            <p>
              <a href="https://www.instagram.com/venezuelaaidlive/">
                <i className="fab fa-instagram fa-2x" />
              </a>
            </p>
          </div>
          <div className="col-xs-2 text-center">
            <p>
              <a href="https://www.youtube.com/channel/UCWLdPPOhZchUcTUwIVelbGA">
                <i className="fab fa-youtube fa-2x" />
              </a>
            </p>
          </div>
          <div className="col-xs-2" />
        </div>
      </div>
    </section>
    <section className="banner-footer">
      <div className="container text-center">
        <div className="col-md-12">
          <h1>
            <FormattedHTMLMessage id="connectFrom" />
            <button  className={'js-header-donate-btn'}>
              <FormattedMessage id="donateNow" />
            </button>
          </h1>
        </div>
      </div>
    </section>
  </div>
)

export default Header
