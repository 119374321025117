import React from 'react'
import PropTypes from 'prop-types'
import Header from '../components/Header'
import Footer from '../components/Footer'

import Helmet from 'react-helmet'
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n'
import { StaticQuery, graphql } from 'gatsby'
import { IntlProvider } from 'react-intl'
import 'intl'
import './index.css'
const Layout = ({ children, location, i18nMessages }) => {
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              languages {
                defaultLangKey
                langs
              }
            }
          }
        }
      `}
      render={data => {
        let langKey = ''
        try {
          const url = location.pathname
          const { langs, defaultLangKey } = data.site.siteMetadata.languages
          langKey = getCurrentLangKey(langs, defaultLangKey, url)
        } catch (e) {
          langKey = 'en'
        }

        return (
          <IntlProvider locale={langKey} messages={i18nMessages}>
            <div>
              {langKey === 'es' ? (
                <Helmet>
                  <title>Venezuela Ayuda&Libertad</title>
                  <html lang="es" />
                  <meta
                    name="description"
                    content="Tú, puedes ser parte de esta reconstrucción así que solo tenemos pocos dias para recaudar 100 millones de dólares."
                  />
                  <meta name="twitter:card" content="summary" />
                  <meta name="twitter:site" content="@aid_Venezuela" />
                  <meta name="twitter:creator" content="@aid_Venezuela" />
                  <meta
                    name="google-site-verification"
                    content="mcj1YJnV23CsRuDvqbzKxTgRqsqQWKtgp3CgT5yOeYY"
                  />
                  <meta
                    property="og:url"
                    content="https://ayudaylibertad.com/"
                  />
                  <meta
                    property="og:title"
                    content="Llegó el momento de actuar por Venezuela"
                  />
                  <meta
                    property="og:description"
                    content="Tú, puedes ser parte de esta reconstrucción así que solo tenemos pocos dias para recaudar 100 millones de dólares."
                  />
                  <meta
                    property="og:image"
                    content="https://www.venezuelaaidlive.com/img/logo.png"
                  />
                  <link
                    rel="alternate"
                    href="https://venezuelaaidlive.com/"
                    hrefLang="en"
                  />
                  <link
                    rel="alternate"
                    href="https://venezuelaaidlive.com/es"
                    hreflang="es"
                  />
                  <link
                    rel="alternate"
                    href="https://venezuelaaidlive.com/"
                    hrefLang="x-default"
                  />
                  <link
                    rel="stylesheet"
                    href="https://use.fontawesome.com/releases/v5.7.1/css/all.css"
                    integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr"
                    crossOrigin="anonymous"
                  />
                  <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/css?family=Nunito:400,600,700"
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="57x57"
                    href="/apple-icon-57x57.png"
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="60x60"
                    href="/apple-icon-60x60.png"
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="72x72"
                    href="/apple-icon-72x72.png"
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="76x76"
                    href="/apple-icon-76x76.png"
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="114x114"
                    href="/apple-icon-114x114.png"
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="120x120"
                    href="/apple-icon-120x120.png"
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="144x144"
                    href="/apple-icon-144x144.png"
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="152x152"
                    href="/apple-icon-152x152.png"
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/apple-icon-180x180.png"
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="192x192"
                    href="/android-icon-192x192.png"
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/favicon-32x32.png"
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="96x96"
                    href="/favicon-96x96.png"
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/favicon-16x16.png"
                  />
                  <link rel="manifest" href="/manifest.json" />
                  <meta name="msapplication-TileColor" content="#ffffff" />
                  <meta
                    name="msapplication-TileImage"
                    content="/ms-icon-144x144.png"
                  />
                  <meta name="theme-color" content="#ffffff" />
                </Helmet>
              ) : (
                <Helmet>
                  <title>Venezuela Aid Live</title>
                  <html lang="en" />
                  <meta
                    name="description"
                    content="This international concert will bring global attention to this unacceptable and preventable crisis and raise funds for essential humanitarian aid for Venezuelan refugees."
                  />
                  <meta name="twitter:card" content="summary" />
                  <meta name="twitter:site" content="@aid_Venezuela" />
                  <meta name="twitter:creator" content="@aid_Venezuela" />
                  <meta
                    name="google-site-verification"
                    content="mcj1YJnV23CsRuDvqbzKxTgRqsqQWKtgp3CgT5yOeYY"
                  />
                  <meta
                    property="og:url"
                    content="https://venezuelaaidlive.com/"
                  />
                  <meta
                    property="og:title"
                    content="Every dollar counts for Venezuela"
                  />
                  <meta
                    property="og:description"
                    content="This international concert will bring global attention to this unacceptable and preventable crisis and raise funds for essential humanitarian aid for Venezuelan refugees."
                  />
                  <meta
                    property="og:image"
                    content="https://www.venezuelaaidlive.com/img/logo.png"
                  />
                  <link
                    rel="canonical"
                    href="https://venezuelaaidlive.com/"
                    hrefLang="en"
                  />
                  <link
                    rel="alternate"
                    href="https://venezuelaaidlive.com/"
                    hrefLang="en"
                  />
                  <link
                    rel="alternate"
                    href="https://venezuelaaidlive.com/es"
                    hrefLang="es"
                  />
                  <link
                    rel="alternate"
                    href="https://venezuelaaidlive.com/"
                    hrefLang="x-default"
                  />
                  <link
                    rel="stylesheet"
                    href="https://use.fontawesome.com/releases/v5.7.1/css/all.css"
                    integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr"
                    crossOrigin="anonymous"
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="57x57"
                    href="/apple-icon-57x57.png"
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="60x60"
                    href="/apple-icon-60x60.png"
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="72x72"
                    href="/apple-icon-72x72.png"
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="76x76"
                    href="/apple-icon-76x76.png"
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="114x114"
                    href="/apple-icon-114x114.png"
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="120x120"
                    href="/apple-icon-120x120.png"
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="144x144"
                    href="/apple-icon-144x144.png"
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="152x152"
                    href="/apple-icon-152x152.png"
                  />
                  <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/apple-icon-180x180.png"
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="192x192"
                    href="/android-icon-192x192.png"
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/favicon-32x32.png"
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="96x96"
                    href="/favicon-96x96.png"
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/favicon-16x16.png"
                  />
                  <link rel="manifest" href="/manifest.json" />
                  <meta name="msapplication-TileColor" content="#ffffff" />
                  <meta
                    name="msapplication-TileImage"
                    content="/ms-icon-144x144.png"
                  />
                  <meta name="theme-color" content="#ffffff" />
                </Helmet>
              )}
              <Header locale={langKey} />
              <div>{children}</div>
              <Footer locale={langKey} />
            </div>
          </IntlProvider>
        )
      }}
    />
  )
}

export default Layout
